<template>
  <v-menu
    :close-on-content-click="false"
    offset-y>
    <template #activator="{ on, attrs }">
      <v-text-field
        :value="display"
        v-bind="attrs"
        :dense="dense"
        :placeholder="placeholder"
        outlined
        readonly
        hide-details
        v-on="on" />
    </template>
    <v-date-picker
      :value="value"
      color="primary"
      locale="th-TH"
      width="300"
      @input="$emit('input', $event)" />
  </v-menu>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: 'dd/mm/yyyy'
    },
    dense: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    display () {
      if (!this.value) {
        return ''
      }
      const date = new Date(this.value).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })
      return date
    }
  }
}
</script>

<style scoped>

</style>
