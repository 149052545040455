<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    nudge-top="-4px"
    content-class="snt-moment-dd__menu"
    origin="center center"
    transition="slide-y-transition"
    offset-y>
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="display"
        v-bind="attrs"
        :placeholder="$t('moment')"
        class="snt-input--small-icon snt-input--rounded snt-input--outlined"
        append-icon="$snt_calendar_blank"
        background-color="#ffffff"
        outlined
        readonly
        hide-details
        dense
        v-on="on"
        @click:append="on.click" />
    </template>
    <v-list
      class="snt-card--outlined snt-card--rounded"
      outlined
      dense>
      <v-list-item
        link
        @click="changeToday()">
        <span> {{ $t('today') }}</span>
      </v-list-item>
      <v-list-item
        link
        @click="changeYesterday()">
        <span> {{ $t('yesterday') }}</span>
      </v-list-item>
      <v-list-item
        link
        @click="changeThisWeek()">
        <span> {{ $t('thisweek') }}</span>
      </v-list-item>
      <v-list-item
        link
        @click="changeLastWeek()">
        <span> {{ $t('lastweek') }}</span>
      </v-list-item>
      <v-list-item
        link
        @click="changeThisMonth()">
        <span> {{ $t('thismonth') }}</span>
      </v-list-item>
      <v-list-item
        link
        @click="changeLastMonth()">
        <span> {{ $t('lastmonth') }}</span>
      </v-list-item>
      <v-row
        class="px-4 py-3"
        dense>
        <v-col cols="6">
          <div class="mb-2">
            {{ $t('from') }}
          </div>
          <div class="snt-moment-dd-range__container">
            <snt-date-picker
              :value="startDate"
              :placeholder="$t('formatDate')"
              dense
              @input="changeValue([$event, endDate])" />
          </div>
        </v-col>
        <v-col cols="6">
          <div class="mb-2">
            {{ $t('to') }}
          </div>
          <div class="snt-moment-dd-range__container">
            <snt-date-picker
              :value="endDate"
              :placeholder="$t('formatDate')"
              dense
              @input="changeValue([startDate, $event])" />
          </div>
        </v-col>
      </v-row>
      <v-list-item
        link
        @click="clear()">
        <span> {{ $t('all') }}</span>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import SntDatePicker from './SntDatePicker.vue'

export default {
  components: { SntDatePicker },
  props: {
    value: {
      type: Array,
      default: () => ([])
    },
    dafaultValue: {
      type: String,
      validator: (val) => ['today', 'yesterday', 'thisweek', 'lastweek', 'thismonth', 'lastmonth'].includes(val),
      default: 'today'
    }
  },
  data () {
    return {
      menu: false,
      selected: 'all',
      format: 'YYYY-MM-DD'
    }
  },
  computed: {
    dateNow () {
      return this.$dayjs(new Date())
    },
    startDate () {
      return this.value && this.value[0] ? this.value[0] : ''
    },
    endDate () {
      return this.value && this.value[1] ? this.value[1] : ''
    },
    display () {
      if (!this.value) {
        return ''
      }
      const startDate = this.startDate
      const endDate = this.endDate
      if (this.$dayjs(startDate).isToday() && this.$dayjs(endDate).isToday()) {
        return this.$t('today')
      }
      if (this.$dayjs(startDate).isYesterday() && this.$dayjs(endDate).isYesterday()) {
        return this.$t('yesterday')
      }
      const thisWeek = this.getThisWeek()
      if (
        this.$dayjs(startDate).isSame(thisWeek[0])
        && this.$dayjs(endDate).isSame(thisWeek[1])
      ) {
        return this.$t('thisweek')
      }
      const lastWeek = this.getLastWeek()
      if (
        this.$dayjs(startDate).isSame(lastWeek[0])
        && this.$dayjs(endDate).isSame(lastWeek[1])
      ) {
        return this.$t('lastweek')
      }
      const thisMonth = this.getThisMonth()
      if (
        this.$dayjs(startDate).isSame(thisMonth[0])
        && this.$dayjs(endDate).isSame(thisMonth[1])
      ) {
        return this.$t('thismonth')
      }
      const lastMonth = this.getLastMonth()
      if (
        this.$dayjs(startDate).isSame(lastMonth[0])
        && this.$dayjs(endDate).isSame(lastMonth[1])
      ) {
        return this.$t('lastmonth')
      }
      return `${new Date(startDate).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })} ~ ${new Date(endDate).toLocaleDateString('th-TH', {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      })}`
    }
  },
  created () {
    switch (this.dafaultValue) {
      case 'today':
        this.changeToday()
        break
      case 'yesterday':
        this.changeYesterday()
        break
      case 'thisweek':
        this.changeThisWeek()
        break
      case 'lastweek':
        this.changeLastWeek()
        break
      case 'thismonth':
        this.changeThisMonth()
        break
      case 'lastmonth':
        this.changeLastMonth()
        break
      default:
        break
    }
  },
  methods: {
    clear () {
      this.selected = 'all'
      this.$emit('input', null)
      this.setMenu(false)
    },
    getThisWeek () {
      const startDate = this.dateNow.day(0)
      const endDate = startDate.day(6)
      return [startDate.format(this.format), endDate.format(this.format)]
    },
    getLastWeek () {
      const startDate = this.dateNow.add(-1, 'week').day(0)
      const endDate = startDate.day(6)
      return [startDate.format(this.format), endDate.format(this.format)]
    },
    getThisMonth () {
      const startDate = this.dateNow.date(1)
      const endDate = startDate.date(startDate.daysInMonth())
      return [startDate.format(this.format), endDate.format(this.format)]
    },
    getLastMonth () {
      const startDate = this.dateNow.date(1).add(-1, 'month')
      const endDate = startDate.date(startDate.daysInMonth())
      return [startDate.format(this.format), endDate.format(this.format)]
    },
    changeToday () {
      const startDate = this.dateNow
      const endDate = this.dateNow
      this.changeValue([startDate.format(this.format), endDate.format(this.format)])
    },
    changeYesterday () {
      const startDate = this.dateNow.add(-1, 'day')
      const endDate = this.dateNow.add(-1, 'day')
      this.changeValue([startDate.format(this.format), endDate.format(this.format)])
    },
    changeThisWeek () {
      this.changeValue(this.getThisWeek())
    },
    changeLastWeek () {
      this.changeValue(this.getLastWeek())
    },
    changeThisMonth () {
      this.changeValue(this.getThisMonth())
    },
    changeLastMonth () {
      this.changeValue(this.getLastMonth())
    },
    changeValue (value) {
      this.$emit('input', value)
      this.setMenu(false)
    },
    setMenu (menu = false) {
      this.$_.delay(() => {
        this.menu = menu
      }, 30)
    }
  }
}
</script>

<style scoped>
  .snt-moment-dd__menu {
    box-shadow: none;
  }
  .snt-moment-dd-range__container {
    width: 115px;
  }
</style>
