<template>
  <v-select
    :value="value"
    :items="items"
    :multiple="multiple"
    :label="label"
    :placeholder="placeholder"
    :item-text="itemText"
    :item-value="itemValue"
    :menu-props="{
      'content-class': 'snt-select__menu snt-card--outlined',
      'origin': 'center center',
      'transition':'slide-y-transition',
      'nudge-top': '-4px',
      'offset-y': true,
    }"
    class="snt-select snt-input--rounded snt-input--outlined"
    background-color="#ffffff"
    outlined
    hide-details
    clearable
    dense
    @input="changeValues($event)">
    <template #selection="{ item, index }">
      <div v-if="multiple">
        <v-chip
          v-if="index === 0"
          small>
          <span>{{ getText(item) }}</span>
        </v-chip>
        <v-chip
          v-if="index === 1"
          small>
          <span>{{ getText(item) }}</span>
        </v-chip>
        <span
          v-if="index === 2"
          class="grey--text caption">
          (+{{ value.length - 2 }} {{ $t('others') }})
        </span>
      </div>
      <div v-else>
        {{ getText(item) }}
      </div>
    </template>
    <template
      v-if="multiple"
      #prepend-item>
      <v-list class="snt-select-multiple__all">
        <v-list-item>
          <v-radio-group
            :value="value.length"
            class="ma-0"
            hide-details
            dense
            @change="changeValues([])">
            <v-radio
              :value="0"
              :label="$t('allCategory')" />
          </v-radio-group>
        </v-list-item>
      </v-list>
    </template>
  </v-select>
</template>

<script>

export default {
  props: {
    value: {
      type: [String, Array],
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    label: {
      type: String,
      default: undefined
    },
    placeholder: {
      type: String,
      default: undefined
    },
    itemText: {
      type: [String, Function],
      default: (v) => v
    },
    itemValue: {
      type: [String, Function],
      default: (v) => v
    },
    multiple: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
    }
  },
  computed: {
    likesAll () {
      return this.value.length === this.items.length
    },
    likesSome () {
      return this.value.length > 0 && !this.likesAll
    },
    icon () {
      if (this.likesAll) return 'mdi-close-box'
      if (this.likesSome) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    getText (item) {
      if (this.$_.isFunction(this.itemText)) {
        return this.itemText(item)
      }
      if (this.itemText) {
        return item[this.itemText]
      }
      return item
    },
    getValue (item) {
      if (this.$_.isFunction(this.itemValue)) {
        return this.itemValue(item)
      }
      if (this.itemValue) {
        return item[this.itemValue]
      }
      return item
    },
    changeValues (value) {
      this.$emit('input', value)
    },
    toggle () {
      this.$nextTick(() => {
        if (this.likesAll) {
          this.$emit('input', [])
        } else {
          this.$emit('input', this.items.map((item) => this.getValue(item)).slice())
        }
      })
    }
  }
}
</script>

<style scoped>
  .snt-select-multiple__all {
    position: sticky;
    top: 0;
    z-index: 1;
    border-bottom: 1px solid #ddd;
  }
</style>
<style>
  .snt-select.v-select.v-text-field input {
    width: 160px;
  }
  .snt-select__menu {
    box-shadow: none;
    border-width: 1px;
    border-radius: 8px;
  }
  .snt-select__menu .v-list {
    padding: 0;
  }
  .snt-select__menu .v-list-item {
    padding: 0 8px;
  }
  .snt-select__menu .v-list-item__action {
    margin: 8px 8px 8px 0 !important;
  }
  .snt-select__menu .v-input--selection-controls__input .v-icon.v-icon {
    font-size: 16px;
  }
</style>
