<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    content-class="snt-template-action__menu"
    min-width="135"
    origin="center center"
    transition="slide-y-transition"
    offset-y>
    <template #activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        icon
        v-on="on">
        <v-icon color="#C4C4C4">
          mdi-dots-vertical
        </v-icon>
      </v-btn>
    </template>
    <v-list
      class="snt-template-action"
      outlined
      dense>
      <approve-button
        v-if="!hideApprove"
        :item-id="itemId"
        @click:submit="submit()" />
      <reject-button
        v-if="!hideReject"
        :item-id="itemId"
        @click:submit="submit()" />
    </v-list>
  </v-menu>
</template>

<script>
import RejectButton from './RejectButton.vue'
import ApproveButton from './ApproveButton.vue'

export default {
  components: { RejectButton, ApproveButton },
  props: {
    itemId: {
      type: [Number, String],
      required: true
    },
    hideApprove: {
      type: Boolean,
      default: false
    },
    hideReject: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      menu: false
    }
  },
  methods: {
    submit () {
      this.menu = false
      this.$emit('click:submit')
    }
  }
}
</script>

<style scoped>
  .snt-template-action.v-list  {
    border-radius: 8px;
    background: #ffffff;
  }
  .snt-template-action__menu {
    box-shadow: none;
  }
</style>
