<template>
  <v-autocomplete
    :value="value"
    :items="filteredSearchItems"
    :placeholder="placeholder"
    :menu-props="{
      'content-class': 'snt-search__menu snt-card--outlined',
      'origin': 'center center',
      'transition':'slide-y-transition',
      'nudge-top': '-4px',
      'offset-y': true,
    }"
    class="snt-input--rounded snt-input--outlined"
    prepend-inner-icon="mdi-magnify"
    background-color="#ffffff"
    outlined
    hide-details
    hide-no-data
    hide-selected
    auto-select-first
    clearable
    cache-items
    dense
    @update:search-input="changeValue($event)"
  />
</template>

<script>
export default {
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    items: {
      type: Array,
      default: () => ([])
    },
    placeholder: {
      type: String,
      default: undefined
    }
  },
  computed: {
    searchItems () {
      if (this.value) {
        return this.$_.concat([this.value], this.items)
      }
      return this.items
    },
    filteredSearchItems () {
      return this.$_.search(this.searchItems, this.value)
    }
  },
  methods: {
    changeValue (value) {
      this.$emit('input', value)
    }
  }
}
</script>

<style>
  .v-autocomplete__content {
    box-shadow: none !important;
    border-radius: 8px !important;
    border-collapse: collapse;
    border-color: #E4E4E4;
    border-style: solid;
    border-width: 1px;
  }
  .v-autocomplete__content .v-list {
    padding: 0 !important;
  }
</style>
