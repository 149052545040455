<template>
  <div class="py-4 px-8">
    <div class="snt-templates__headline">
      {{ $t('templateAdminManage') }}
    </div>
    <div class="d-flex column-gap-3 mb-4">
      <div class="snt-templates-moment">
        <snt-moment-dropdown
          :value="moment"
          dafault-value="thismonth"
          @input="setMoment($event)" />
      </div>
      <div class="snt-templates-category">
        <snt-select
          :value="categories"
          :items="salePageTemplateCategoryEnum"
          :placeholder="$t('category')"
          item-text="description"
          item-value="name"
          multiple
          @input="setCategories($event)" />
      </div>
      <div class="snt-templates-search">
        <snt-search
          :value="search"
          :items="searchItems"
          :placeholder="$t('search')"
          @input="debounceSetSearch($event)" />
      </div>
    </div>
    <div class="snt-card--rounded snt-card--outlined">
      <v-data-table
        v-model="selected"
        :headers="filteredHeaders"
        :items="salePageTemplateList"
        :loading="loading"
        :options.sync="options"
        :no-data-text="$t('nodata')"
        class="snt-table-templates"
        item-key="id"
        hide-default-footer
        show-select>
        <template #[`header.data-table-select`]="{ on, props }">
          <v-checkbox
            v-bind="props"
            :ripple="false"
            class="mt-0 pt-0"
            hide-details
            dense
            @change="on.input($event)" />
        </template>
        <template #[`item.data-table-select`]="{ select, isSelected }">
          <v-checkbox
            :value="isSelected"
            :ripple="false"
            class="mt-0 pt-0"
            hide-details
            dense
            @change="select($event)" />
        </template>
        <template #top>
          <div class="snt-template-action d-flex align-center px-4 py-2">
            <v-chip-group
              v-model="tagIndex"
              active-class="primary"
              mandatory
              column
              @change="setStatus($event)">
              <v-chip
                class="snt-template-status__chip"
                color="#C5C5C5"
                dark>
                {{ $t('templateAdminStatus.in_review') }}
                <v-avatar
                  v-if="countInReview"
                  :color="tagIndex === 0 ? '#EDA437' : '#B8B8B8'"
                  dark
                  right>
                  {{ countInReview }}
                </v-avatar>
              </v-chip>
              <v-chip
                class="snt-template-status__chip"
                color="#C5C5C5"
                dark>
                {{ $t('templateAdminStatus.published') }}
                <v-avatar
                  v-if="countPublished"
                  :color="tagIndex === 1 ? '#EDA437' : '#B8B8B8'"
                  dark
                  right>
                  {{ countPublished }}
                </v-avatar>
              </v-chip>
              <v-chip
                class="snt-template-status__chip"
                color="#C5C5C5"
                dark>
                {{ $t('templateAdminStatus.rejected') }}
                <v-avatar
                  v-if="countRejected"
                  :color="tagIndex === 2 ? '#EDA437' : '#B8B8B8'"
                  dark
                  right>
                  {{ countRejected }}
                </v-avatar>
              </v-chip>
              <v-chip
                class="snt-template-status__chip"
                color="#C5C5C5"
                dark>
                {{ $t('all') }}
              </v-chip>
            </v-chip-group>
            <v-spacer />
            <div class="d-flex column-gap-4">
              <reject-all-button
                v-if="tagIndex !== 2 && tagIndex !== 3"
                :selected="selected"
                :disabled="!selected.length"
                @click:submit="reload()" />
              <approve-all-button
                v-if="tagIndex !== 1 && tagIndex !== 3"
                :selected="selected"
                :disabled="!selected.length"
                @click:submit="reload()" />
            </div>
          </div>
        </template>
        <template #[`item.createdAt`]="{ item }">
          {{ item.createdAt | formatDateTime }}
        </template>
        <template #[`item.latestStatus`]="{ item }">
          <v-chip
            :color="getStatusColor(item.latestStatus)"
            class="px-4">
            {{ getStatusI18n(item.latestStatus) }}
          </v-chip>
        </template>
        <template #[`item.actions`]="{ item }">
          <div class="d-flex column-gap-4">
            <v-btn
              :to="{ name: 'Editor', params: { slug: item.id } }"
              :outlined="tagIndex === 3"
              class="snt-btn--rounded "
              color="primary"
              target="_blank"
              depressed>
              {{ $t('preview') }}
            </v-btn>
            <!-- <preview-button
              :item-id="item.id"
              :item-name="item.name"
              :item-categories="item.categories"
              :item-landing="item.landing"
              :item-products="item.products"
              :item-product="item.product"
              :item-cart="item.cart"
              :item-orders="item.orders"
              :item-order="item.order"
              :item-checkout="item.checkout"
              :item-stylesheet="item.stylesheet"
              :outlined="tagIndex === 3" /> -->
            <action-button
              v-if="tagIndex === 3"
              :item-id="item.id"
              :hide-approve="item.latestStatus === 'PUBLISHED'"
              :hide-reject="item.latestStatus === 'REJECTED'"
              @click:submit="reload()" />
            <template v-else>
              <approve-button
                v-if="item.latestStatus !== 'PUBLISHED'"
                :item-id="item.id"
                btn
                @click:submit="reload()" />
              <reject-button
                v-if="item.latestStatus !== 'REJECTED'"
                :item-id="item.id"
                btn
                @click:submit="reload()" />
            </template>
          </div>
        </template>
        <template #[`footer`]>
          <v-pagination
            v-model="page"
            :length="pageCount"
            class="pa-6" />
        </template>
      </v-data-table>
    </div>
  </div>
</template>

<script>
import {
  GET_SALEPAGETEMPLATELIST,
  GET_SALEPAGETEMPLATECATEGORY_ENUM,
  GET_SALEPAGETEMPLATESTATUS_ENUM
} from '@/resources/graphql'
import SntMomentDropdown from '@/components/form/SntMomentDropdown.vue'
import SntSelect from '@/components/form/SntSelect.vue'
import SntSearch from '@/components/form/SntSearch.vue'
// import PreviewButton from './components/PreviewButton.vue'
import ActionButton from './components/ActionButton.vue'
import ApproveButton from './components/ApproveButton.vue'
import RejectButton from './components/RejectButton.vue'
import ApproveAllButton from './components/ApproveAllButton.vue'
import RejectAllButton from './components/RejectAllButton.vue'

export default {
  components: {
    SntMomentDropdown,
    SntSelect,
    SntSearch,
    // PreviewButton,
    ActionButton,
    ApproveButton,
    RejectButton,
    ApproveAllButton,
    RejectAllButton
  },
  apollo: {
    salePageTemplateStatusEnum: () => ({
      query: GET_SALEPAGETEMPLATESTATUS_ENUM,
      update (data) {
        return data.__type.enumValues
      }
    }),
    salePageTemplateCategoryEnum: () => ({
      query: GET_SALEPAGETEMPLATECATEGORY_ENUM,
      update ({ __type }) {
        return __type.enumValues.map((v) => ({
          ...v,
          description: this.$t(v.description)
        }))
      }
    }),
    countInReview: () => ({
      query: GET_SALEPAGETEMPLATELIST,
      variables () {
        return {
          page: 1,
          perPage: 1,
          filter: {
            status: 'IN_REVIEW',
            category: this.category ? [this.category] : [],
            search: this.search,
            startDate: this.startDate,
            endDate: this.endDate
          }
        }
      },
      update: ({ salePageTemplateList }) => salePageTemplateList.nodesCount
    }),
    countPublished: () => ({
      query: GET_SALEPAGETEMPLATELIST,
      variables () {
        return {
          page: 1,
          perPage: 1,
          filter: {
            status: 'PUBLISHED',
            category: this.category ? [this.category] : [],
            search: this.search,
            startDate: this.startDate,
            endDate: this.endDate
          }
        }
      },
      update: ({ salePageTemplateList }) => salePageTemplateList.nodesCount
    }),
    countRejected: () => ({
      query: GET_SALEPAGETEMPLATELIST,
      variables () {
        return {
          page: 1,
          perPage: 1,
          filter: {
            status: 'REJECTED',
            category: this.category ? [this.category] : [],
            search: this.search,
            startDate: this.startDate,
            endDate: this.endDate
          }
        }
      },
      update: ({ salePageTemplateList }) => salePageTemplateList.nodesCount
    }),
    salePageTemplateList: () => ({
      query: GET_SALEPAGETEMPLATELIST,
      fetchPolicy: 'no-cache',
      variables () {
        return {
          page: this.page,
          perPage: 8,
          orderBy: this.orderBy,
          filter: {
            status: this.status,
            category: this.categories,
            search: this.search,
            startDate: this.startDate,
            endDate: this.endDate
          }
        }
      },
      update ({ salePageTemplateList }) {
        return this.status
          ? salePageTemplateList.nodes.filter((item) => item.latestStatus === this.status)
          : salePageTemplateList.nodes
      },
      result ({ data: { salePageTemplateList } }) {
        this.pageCount = salePageTemplateList.pagesCount
      }
    })
  },
  data () {
    const startDate = this.$dayjs(new Date()).date(1)
    const endDate = startDate.date(startDate.daysInMonth())
    return {
      tagIndex: 0,
      salePageTemplateStatusEnum: [],
      salePageTemplateCategoryEnum: [],
      salePageTemplateList: [],
      loading: false,
      submitting: false,
      search: '',
      categories: [],
      moment: [startDate.format('YYYY-MM-DD'), endDate.format('YYYY-MM-DD')],
      templates: [],
      orderBy: ['id_ASC'],
      options: {
        sortBy: [],
        sortDesc: []
      },
      status: 'IN_REVIEW',
      page: 1,
      pageCount: 0,
      selected: [],
      headers: [
        {
          text: this.$t('createdAt'),
          align: 'start',
          value: 'createdAt',
          class: 'snt-table-templates--medium',
          cellClass: 'snt-table-templates--medium'
        },
        {
          text: this.$t('name'),
          align: 'start',
          value: 'name',
          sortable: false,
          class: 'snt-table-templates--medium',
          cellClass: 'snt-table-templates--medium'
        },
        {
          text: this.$t('version'),
          align: 'start',
          value: 'version',
          sortable: false,
          class: 'snt-table-templates--medium',
          cellClass: 'snt-table-templates--medium'
        },
        {
          text: this.$t('step'),
          align: 'start',
          value: 'sectionsCount',
          class: 'snt-table-templates--medium',
          cellClass: 'snt-table-templates--medium'
        },
        {
          text: this.$t('templateOwner'),
          align: 'start',
          value: 'user.email',
          sortable: false,
          class: 'snt-table-templates--medium',
          cellClass: 'snt-table-templates--medium'
        },
        {
          text: this.$t('status'),
          align: 'start',
          value: 'latestStatus',
          sortable: false,
          class: 'snt-table-templates--medium',
          cellClass: 'snt-table-templates--medium'
        },
        {
          text: '',
          value: 'actions',
          sortable: false
        }
      ]
    }
  },
  computed: {
    filteredHeaders () {
      return this.tagIndex === 3 ? this.headers : this.headers.filter((v) => v.value !== 'latestStatus')
    },
    startDate () {
      return this.moment && this.moment[0] ? this.moment[0] : null
    },
    endDate () {
      return this.moment && this.moment[1] ? this.moment[1] : null
    },
    searchItems () {
      return this.search ? this.$_.flattenDeep(this.salePageTemplateList.map((item) => [item.name])) : []
    }
  },
  watch: {
    options: {
      handler () {
        this.page = 1
        const { sortBy, sortDesc } = this.options
        if (sortBy.length && sortDesc.length) {
          switch (sortBy[0]) {
            case 'sectionsCount':
              this.orderBy = 'sections_count'
              break
            default:
              this.orderBy = 'id'
              break
          }
          if (sortDesc[0]) {
            this.orderBy += '_DESC'
          } else {
            this.orderBy += '_ASC'
          }
        }
      },
      deep: true
    }
  },
  created () {
    this.debounceSetSearch = this.$_.debounce(this.setSearch, 800)
  },
  methods: {
    getStatusI18n (statusName) {
      const status = this.$_.find(this.salePageTemplateStatusEnum, (v) => v.name === statusName) || {}
      return status.description ? this.$t(`templateAdminStatus.${status.description}`) : '...'
    },
    getStatusColor (status) {
      switch (status) {
        case 'IN_REVIEW':
          return 'primary'
        case 'PUBLISHED':
          return 'success'
        case 'REJECTED':
          return 'error'
        case 'DRAFT':
          return 'warning'
        default:
          return 'default'
      }
    },
    setStatus (value) {
      this.page = 1
      switch (value) {
        case 0:
          this.status = 'IN_REVIEW'
          break
        case 1:
          this.status = 'PUBLISHED'
          break
        case 2:
          this.status = 'REJECTED'
          break
        default:
          this.status = null
          break
      }
      this.selected = []
      this.$apollo.queries.salePageTemplateList.refetch()
    },
    setMoment (moment) {
      this.page = 1
      this.moment = moment
    },
    setSearch (search = '') {
      this.page = 1
      this.search = search
    },
    setCategories (categories) {
      this.page = 1
      this.categories = categories
    },
    reload () {
      this.$apollo.queries.countInReview.refetch()
      this.$apollo.queries.countPublished.refetch()
      this.$apollo.queries.countRejected.refetch()
      this.$apollo.queries.salePageTemplateList.refetch()
    }
  }
}
</script>
<style>
  .snt-table-templates--medium {
    font-size: 0.875rem !important;
  }
</style>
<style scoped>
  .snt-templates__headline {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    letter-spacing: 0.01em;
    color: #6B6B6B;
    margin-bottom: 16px;
  }
  .snt-templates-search {
    width: 320px;
  }
  .snt-template-action {
    border-bottom: thin solid rgba(0, 0, 0, 0.12);
  }
</style>
