<template>
  <v-dialog
    v-model="dialog"
    width="320px"
    scrollable>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        class="snt-template-action snt-btn--rounded"
        color="error"
        outlined
        :disabled="disabled"
        v-on="on">
        {{ $t('rejectSelected') }}
      </v-btn>
    </template>
    <v-card class="snt-template-reject__card">
      <v-card-title class="pt-6 px-6 pb-0"></v-card-title>
      <v-card-text class="px-6">
        <div class="snt-template-reject__action">
          <v-btn
            x-small
            icon
            @click="setDialog(false)">
            <v-icon
              color="#6C6C6C"
              size="14">
              mdi-close
            </v-icon>
          </v-btn>
        </div>
        <div class="text-center mb-4">
          <v-icon size="53">
            $snt_bad_review
          </v-icon>
        </div>
        <div class="snt-template-reject__title">
          {{ $t('reject.title') }}
        </div>
        <div class="d-flex justify-center">
          <v-checkbox
            v-model="checkbox"
            :label="$t('reject.checkbox')"
            hide-details
            dense />
        </div>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation>
        </v-form>
      </v-card-text>
      <v-card-actions class="pb-6 px-6">
        <v-spacer />
        <v-btn
          class="snt-template-reject__btn px-4"
          text
          @click="setDialog(false)">
          {{ $t('cancel') }}
        </v-btn>
        <v-btn
          :loading="submitting"
          :disabled="!checkbox"
          class="snt-template-reject__btn px-4 ml-3"
          color="error"
          depressed
          @click="submit()">
          {{ $t('rejectSelected') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { UPDATE_STATUS_SALEPAGETEMPLATE } from '@/resources/graphql'

export default {
  props: {
    selected: {
      type: [Array],
      default: () => ([])
    },
    disabled: {
      type: [Boolean],
      default: false
    }
  },
  data () {
    return {
      dialog: false,
      submitting: false,
      valid: false,
      checkbox: false
    }
  },
  methods: {
    async reject (id) {
      await this.$apollo.mutate({
        mutation: UPDATE_STATUS_SALEPAGETEMPLATE,
        variables: {
          input: {
            id,
            status: 'REJECTED'
          }
        }
      })
    },
    async submit () {
      if (this.checkbox) {
        this.submitting = true
        const reqs = []
        this.selected.forEach((item) => {
          reqs.push(
            this.reject(item.id)
          )
        })
        await Promise.all(reqs)
        this.submitting = false
        this.setDialog(false)
        this.$emit('click:submit')
      }
    },
    setDialog (dialog = false) {
      this.dialog = dialog
    }
  }
}
</script>

<style scoped>
  .snt-template-action .v-list-item__subtitle {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    color: #6B6B6B;
  }
  .snt-template-reject__card {
    border-radius: 8px;
  }
  .snt-template-reject__action {
    position: absolute;
    right: 20px;
    top: 20px;
  }
  .snt-template-reject__title {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #6B6B6B;
  }
  .snt-template-reject__description {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.01em;
    color: #B1B1B1;
  }
  .snt-template-reject__btn {
    border-radius: 8px;
  }
  .snt-template-action__btn.v-size--default {
    height: 40px;
  }
</style>
