var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-select',{staticClass:"snt-select snt-input--rounded snt-input--outlined",attrs:{"value":_vm.value,"items":_vm.items,"multiple":_vm.multiple,"label":_vm.label,"placeholder":_vm.placeholder,"item-text":_vm.itemText,"item-value":_vm.itemValue,"menu-props":{
    'content-class': 'snt-select__menu snt-card--outlined',
    'origin': 'center center',
    'transition':'slide-y-transition',
    'nudge-top': '-4px',
    'offset-y': true,
  },"background-color":"#ffffff","outlined":"","hide-details":"","clearable":"","dense":""},on:{"input":function($event){return _vm.changeValues($event)}},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
  var item = ref.item;
  var index = ref.index;
return [(_vm.multiple)?_c('div',[(index === 0)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.getText(item)))])]):_vm._e(),(index === 1)?_c('v-chip',{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(_vm.getText(item)))])]):_vm._e(),(index === 2)?_c('span',{staticClass:"grey--text caption"},[_vm._v(" (+"+_vm._s(_vm.value.length - 2)+" "+_vm._s(_vm.$t('others'))+") ")]):_vm._e()],1):_c('div',[_vm._v(" "+_vm._s(_vm.getText(item))+" ")])]}},(_vm.multiple)?{key:"prepend-item",fn:function(){return [_c('v-list',{staticClass:"snt-select-multiple__all"},[_c('v-list-item',[_c('v-radio-group',{staticClass:"ma-0",attrs:{"value":_vm.value.length,"hide-details":"","dense":""},on:{"change":function($event){return _vm.changeValues([])}}},[_c('v-radio',{attrs:{"value":0,"label":_vm.$t('allCategory')}})],1)],1)],1)]},proxy:true}:null],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }